import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from './PageSubTitle.scss';

const PageSubTitle = memo(({ display, text }) => {
  if (!display || !text) return null;

  return (
    <div className={styles.subTitle}>
      <Typography element="h2" styleAs="paragraph" data-testid="page-sub-title">
        {text}
      </Typography>
    </div>
  );
});

PageSubTitle.propTypes = {
  display: PropTypes.bool,
  text: PropTypes.string,
};

PageSubTitle.defaultProps = {
  display: false,
  text: undefined,
};

PageSubTitle.displayName = 'PageSubTitle';

export default PageSubTitle;
